






























import { Component, Vue, Watch } from 'vue-property-decorator';
import { H3List } from '@h3/thinking-ui';
import * as Utils from '@WorkPlatform/common/utils';

@Component({
  name: 'mWorkPlatFormHome',
  components: {
    H3List,
    homeTodo: () => import('./homeTodo.vue'),
    homeAllApp: () => import('./homeAllApp.vue'),
  },
})
export default class mWorkPlatFormHome extends Vue {
  loading: boolean = false;

  todoLoad: boolean = false;

  todoLength: number = 0;

  allAppLoad: boolean = false;

  allAppLength: number = 0;

  get appLoading () {
    // 只要还有一个模块在加载中就是加载中
    return this.todoLoad || this.allAppLoad;
  }

  @Watch('appLoading')
  showToast (show: boolean) {
    console.log(show);
    if (show) {
      this.$toast.show({
        icon: 'loading',
        text: '加载中',
      });
    } else {
      this.$toast.hide();
    }
  }

  async getAllApp () {
    const homeAllApp: any = await Utils.getComponentByRefs(this.$refs, 'homeAllApp');

    if (homeAllApp && !homeAllApp.onlineAppList.length) {
      await homeAllApp.nextPage();
    }
  }

  async getTodoList () {
    const homeTodo: any = await Utils.getComponentByRefs(this.$refs, 'homeTodo');

    if (homeTodo && !homeTodo.list.length) {
      await homeTodo.getTodoList();
    }
  }

  getListLength (val) {
    this.todoLength = val;
  }

  getAllAppLength (val) {
    this.allAppLength = val;
  }

  async mounted () {
    this.todoLoad = true;
    this.allAppLoad = true;

    setTimeout(async () => {
      await this.$nextTick();
      this.getTodoList().finally(() => {
        this.todoLoad = false;
      });

      this.getAllApp().finally(() => {
        this.allAppLoad = false;
      });
    });
  }
}
